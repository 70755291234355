import componentI18n from '@/i18n/componentI18n';
import { defineComponent } from 'vue';
import MaturityImages from '@/components/MaturityImages.vue';
export default defineComponent({
    name: 'LicenseSubscription',
    components: {
        MaturityImages
    },
    ...componentI18n('views.legal.license_subscription')
});
